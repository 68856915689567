@use "sass:color"; @import "src/styles/variables.module"; @import "src/styles/mixins.module";
.card {
  display: flex;
  flex-direction: column-reverse;
  margin: 0 auto;
  padding: 32px;
  background: rgba($color-purple, 0.02);
  border: 0.5px solid rgba($color-neutral-50, 0.5);
  border-radius: 20px;
  box-shadow: inset 0 0 5px 2px rgba($color-pink, 0.3);

  & + & {
    margin-top: 24px;
  }

  &.right {
    flex-direction: column;
  }

  @media (min-width: $breakpoint-md) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    max-width: 1075px;
    padding: 32px 64px;
    text-align: unset;

    & + & {
      margin-top: 64px;
    }

    &.right {
      flex-direction: row;
    }
  }
}

.cardImage {
  position: relative;
  width: 100px;
  height: 100px;
  margin: 0 auto;

  @media (min-width: $breakpoint-md) {
    width: auto;
    height: auto;
    margin: 0;
  }
}

.cardBody {
  @media (min-width: $breakpoint-md) {
    &.right {
      .cardTitle,
      .cardDescription {
        float: right;
        text-align: right;
      }
    }
  }
}

.cardTitle {
  margin: 24px 0;
  font-size: 24px;
  line-height: 29px;

  @media (min-width: $breakpoint-md) {
    margin: 0 0 24px;
    font-size: 40px;
    line-height: 40px;
  }
}

.cardDescription {
  font-weight: 300;
  font-size: 14px;

  @media (min-width: $breakpoint-md) {
    max-width: 400px;
    font-size: 16px;
    line-height: 24px;
  }
}
