@use "sass:color"; @import "src/styles/variables.module"; @import "src/styles/mixins.module";
.footer {
  padding: 64px 0 48px;
  color: $color-lavender;
  font-weight: 300;
  line-height: 1;

  @media (min-width: $breakpoint-md) {
    padding: 128px 0 48px;
  }
}

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;
}

.journey {
  display: flex;
  flex: 0 70%;
  gap: 16px;
  align-items: center;

  @media (min-width: $breakpoint-md) {
    flex: none;
  }
}

.logo {
  position: relative;
}

.copyright,
.pagesItem a,
.socialItem a {
  opacity: 0.5;
}

.copyright,
.pagesItem {
  font-size: 12px;
  line-height: 18px;

  @media (min-width: $breakpoint-md) {
    font-size: 16px;
    line-height: 24px;
  }
}

.copyright {
  @media (max-width: $breakpoint-md) {
    flex: 0 80%;
  }
}

.pagesList {
  display: flex;
  gap: 32px;
  align-items: center;
  justify-content: space-between;
  list-style: none;

  @media (min-width: $breakpoint-md) {
    justify-content: normal;
  }
}

.pagesItem a:hover,
.socialItem a:hover {
  text-decoration: underline;
  opacity: 0.7;
}

.socialList {
  display: flex;
  gap: 16px;
  list-style: none;

  @media (min-width: $breakpoint-md) {
    gap: 24px;
  }
}
