@use "sass:color"; @import "src/styles/variables.module"; @import "src/styles/mixins.module";
.cards {
  padding-top: 50px;
  text-align: center;

  @media (min-width: $breakpoint-md) {
    padding-top: 100px;
    text-align: unset;
  }
}

.cloudCard {
  background-image: radial-gradient(
    75% 100% at 50% 150%,
    rgba($color-pink, 0.6) 0%,
    color.adjust($color-neutral-50, $alpha: -1) 100%
  );
}

.clockCard {
  background-image: radial-gradient(
    75% 100% at 50% 150%,
    rgba($color-pink, 0.6) 0%,
    color.adjust($color-neutral-50, $alpha: -1) 100%
  );

  @media (max-width: $breakpoint-md) {
    background-image: radial-gradient(
      75% 100% at 85% -30%,
      rgba($color-pink, 0.6) 0%,
      color.adjust($color-neutral-50, $alpha: -1) 100%
    );
  }
}

.lockCard {
  background-image: radial-gradient(
    75% 100% at 50% -30%,
    rgba($color-pink, 0.6) 0%,
    color.adjust($color-neutral-50, $alpha: -1) 100%
  );

  @media (max-width: $breakpoint-md) {
    background-image: radial-gradient(
      75% 100% at -30% 85%,
      rgba($color-pink, 0.6) 0%,
      color.adjust($color-neutral-50, $alpha: -1) 100%
    );
  }
}

.mapCard {
  background-image: radial-gradient(
    75% 100% at 10% -35%,
    rgba($color-pink, 0.6) 0%,
    color.adjust($color-neutral-50, $alpha: -1) 100%
  );

  @media (max-width: $breakpoint-md) {
    background-image: radial-gradient(
      75% 100% at 50% -30%,
      rgba($color-pink, 0.6) 0%,
      color.adjust($color-neutral-50, $alpha: -1) 100%
    );
  }
}
