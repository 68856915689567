@use "sass:color"; @import "src/styles/variables.module"; @import "src/styles/mixins.module";
.downloadApp {
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: center;
}

.macOS {
  display: none;

  @media (min-width: $breakpoint-md) {
    display: inline-block;
  }
}
