@use "sass:color"; @import "src/styles/variables.module"; @import "src/styles/mixins.module";
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 32px 0;
}

.social {
  display: flex;
  gap: 24px;
  list-style: none;
}
