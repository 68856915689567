@use "sass:color"; @import "src/styles/variables.module"; @import "src/styles/mixins.module";
.waitlist {
  padding-top: 64px;
  text-align: center;

  &.waitlistPage {
    margin-top: 16px;
    padding: 0;

    @media (min-width: $breakpoint-md) {
      margin-top: 64px;
    }
  }
}

.title {
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;

  @media (min-width: $breakpoint-md) {
    font-size: 40px;
    line-height: 40px;
  }

  @media (max-width: $breakpoint-md) {
    &.waitlistPage {
      font-size: 32px;
      line-height: 40px;
    }
  }
}

.description {
  margin: 10px 0 0;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;

  @media (min-width: $breakpoint-md) {
    margin: 20px 0 0;
    font-size: 16px;
    line-height: 24px;
  }

  @media (max-width: $breakpoint-md) {
    &.waitlistPage {
      margin: 10px 0 30px;
      font-size: 16px;
      line-height: 24px;
    }
  }
}

.expandButton {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: rgba($color-lavender, 0.5);
  background: none;
  border: none;
  appearance: none;
}

.cta {
  @media (min-width: $breakpoint-md) {
    display: none;
  }
}

.downloadApp {
  margin: 24px 0;
}
