@use "sass:color"; @import "src/styles/variables.module"; @import "src/styles/mixins.module";
.button {
  position: relative;
  display: inline-flex;
  align-items: center;
  height: 38px;
  padding: 0 16px;
  color: $color-secondary-light;
  font-weight: 400;
  background: $color-neutral-50;
  border: none;
  border-radius: 6px;
  transition: all 150ms ease-in-out;

  &:after {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background-color: $color-secondary-light;
    border-radius: 6px;
    opacity: 0.7;
    filter: blur(10px);
    transition: all 150ms ease-in-out;
    content: '';
    pointer-events: none;
  }

  &:hover {
    color: $color-neutral-50;
    background-color: $color-secondary-default;
  }

  &:active {
    color: $color-neutral-200;
    background-color: $color-secondary-default;

    &:after {
      opacity: 0.5;
      filter: blur(5px);
    }
  }

  &:disabled {
    color: $color-neutral-600;
    background-color: $color-neutral-850;
    cursor: default;
    pointer-events: none;

    &:after {
      background: none;
      opacity: 0;
      filter: blur(0);
    }
  }

  .loading {
    display: inline-flex;
    margin-left: 12px;
  }

  @media (min-width: $breakpoint-md) {
    height: 40px;
    padding: 0 32px;
  }
}
