@use "sass:color"; @import "src/styles/variables.module"; @import "src/styles/mixins.module";
.hero {
  padding-top: 16px;
  text-align: center;

  @media (min-width: $breakpoint-md) {
    padding-top: 64px;
  }
}

.description {
  margin: 16px auto 0;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;

  @media (min-width: $breakpoint-md) {
    max-width: 560px;
    margin: 48px auto 0;
    font-size: 20px;
    line-height: 30px;
  }
}

.downloadApp {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 24px;
}

.expandButton {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: rgba($color-lavender, 0.5);
  background: none;
  border: none;
  appearance: none;
}
