@use "sass:color"; @import "src/styles/variables.module"; @import "src/styles/mixins.module";
.waitlistWrapper {
  padding: 0 24px;

  @media (min-width: $breakpoint-md) {
    padding: 0;
  }
}

.form {
  display: block;
  flex-direction: column;
  align-items: center;

  @media (min-width: $breakpoint-md) {
    display: inline-flex;
  }
}

.formInputGroup {
  display: inline-flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
  width: 100%;

  @media (min-width: $breakpoint-md) {
    flex-direction: row;
    gap: 24px;
  }
}

.error {
  margin-top: 10px;
  color: $color-pink;
  font-size: 12px;
  text-align: center;

  @media (min-width: $breakpoint-md) {
    text-align: left;
  }
}

.center {
  text-align: center;
}

.title {
  color: $color-pink;
  font-weight: 700;
  font-size: 20px;
  line-height: 170%;
}

.description {
  color: $color-neutral-50;
  font-size: 16px;
}

.formPlatformGroup {
  display: inline-grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin: 20px 0 30px;

  @media (min-width: $breakpoint-md) {
    display: flex;
    flex-direction: row;
    gap: 30px;
    justify-content: center;
  }
}

.formPlatform {
  display: flex;
  gap: 10px;
  align-items: center;
}

.formInput {
  width: 100%;

  @media (min-width: $breakpoint-md) {
    width: 345px;
  }
}
